import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'common/hooks'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { useNavigate } from 'react-router-dom'
import { LARGE_MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { STATE_IDS, STATES_ORDER } from 'modules/loan-application/constants'
import {
  approveFinalInspection,
  downloadAllInspectionAttachments,
  getInspectionDocuments,
  grantFinalInspectionFunds,
  removeInspectionComment,
  requestFinalInspectionFunds,
  submitInspectionComment,
  submitInspectionForApproval,
  updateInspectionAttachmentStatus,
  uploadInspectionAttachment,
} from 'modules/loan-application/actions'
import { styles } from 'modules/loan-application/ntp/style'
import { CLAIMS } from 'common/claims'
import Loader from 'components/loader'
import Button, { BUTTON_VARIANTS } from 'components/button'
import { AccessWrapper, hasAccess } from 'common/access'
import InspectionDocumentsComponent from '../ntp-utils/inspection-documents'
import CommentsSection from '../ntp/comments-section'
import { areNtpAttachmentsApproved } from '../ntp/helpers'
import InspectionButtons from '../ntp-utils/inspection-buttons'

const { Download02Icon } = assets

const NTPInspection = ({
  loanApplicationId,
  inspections,
  dispatch,
  currentState,
  userData,
}) => {
  const [loading, setLoading] = useState(false)
  const { t: translate } = useTranslation()
  const isTabletView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${LARGE_MOBILE_BREAKPOINT}px)`)
  const [downloadAllLoading, setDownloadAllLoading] = useState(false)
  const [comments, setComments] = useState([])
  const navigate = useNavigate()
  const [inspectionId, setInspectionId] = useState(null)
  const { name: currentStateName } = currentState
  const crtStateOrder = STATES_ORDER[currentStateName]
  const userId = userData?.associations[0]?.userId

  const isInspectionApproved =
    crtStateOrder >= STATES_ORDER.FinalInspectionDocsApproved
  const isInspectionSubmitted =
    crtStateOrder >= STATES_ORDER.FinalInspectionDocsPendingApproval
  const areFundsRequested =
    crtStateOrder >= STATES_ORDER.FinalInspectionFundsRequested
  const areFundsGranted =
    crtStateOrder >= STATES_ORDER.FinalInspectionFundsGranted

  const canApprove = areNtpAttachmentsApproved(
    inspections,
    'inspectionAttachments'
  )
  const canSubmitForApproval =
    inspections.every((el) => el.inspectionAttachments?.length > 0) &&
    inspections.length > 0
  const uploadedCount = inspections.filter(
    (el) => el.inspectionAttachments.length > 0
  ).length

  /** On mount get data */
  useEffect(() => {
    loadInspectionFiles()
  }, [])

  const loadInspectionFiles = () => {
    setLoading(true)
    getInspectionDocuments(dispatch, loanApplicationId)
      .then((result) => {
        if (result) {
          setInspectionId(result.inspectionId)
          setComments(result.comments)
        }
      })
      .finally(() => setLoading(false))
  }

  /** Download all attachments */
  const downloadAll = () => {
    setDownloadAllLoading(true)
    downloadAllInspectionAttachments(loanApplicationId, inspectionId).finally(
      () => setDownloadAllLoading(false)
    )
  }

  const submitComment = (comment) => {
    return submitInspectionComment(
      loanApplicationId,
      comment,
      inspectionId
    ).then(() => loadInspectionFiles())
  }
  const removeComment = (commentId) => {
    return removeInspectionComment(commentId).then(() => {
      loadInspectionFiles()
    })
  }

  const _renderButtons = () => {
    return (
      <div style={styles.ntpButtonsWrapper}>
        <InspectionButtons
          canApprove={canApprove}
          canSubmitForApproval={canSubmitForApproval}
          isApproved={isInspectionApproved}
          isSubmitted={isInspectionSubmitted}
          areFundsRequested={areFundsRequested}
          areFundsGranted={areFundsGranted}
          onSubmit={() =>
            submitInspectionForApproval(dispatch, loanApplicationId)
          }
          onApprove={() => approveFinalInspection(dispatch, loanApplicationId)}
          onRequest={() =>
            requestFinalInspectionFunds(dispatch, loanApplicationId)
          }
          onGrant={(amount) =>
            grantFinalInspectionFunds(
              dispatch,
              loanApplicationId,
              amount,
              navigate
            )
          }
          approveLabel={translate(
            'loanApplication.ntpStepsCommon.approveInspection'
          )}
          requestLabel={translate(
            'loanApplication.ntpStepsCommon.requestInspectionFunds'
          )}
          grantLabel={translate(
            'loanApplication.ntpStepsCommon.grantInspectionFunds'
          )}
          hasSubmitAccess={hasAccess(userData, CLAIMS.CAN_SUBMIT_INSPECTION)}
          hasApproveAccess={hasAccess(userData, CLAIMS.CAN_APPROVE_INSPECTION)}
          hasRequestAccess={hasAccess(
            userData,
            CLAIMS.CAN_REQUEST_INSPECTION_FUNDS
          )}
          hasGrantAccess={hasAccess(
            userData,
            CLAIMS.CAN_GRANT_INSPECTION_FUNDS
          )}
        />
      </div>
    )
  }

  return (
    <div style={styles.ntpWrapper}>
      <div style={isTabletView ? styles.ntpContentMobile : styles.ntpContent}>
        <div>
          <div style={styles.header}>
            <div style={styles.headerContent}>
              <div style={styles.headerSubheaderInitial}>
                {translate('loanApplication.navigation.step5Inspection')}
                <div style={styles.subHeader}>
                  {uploadedCount}/{inspections.length}{' '}
                  {translate(
                    'loanApplication.ntpStepsCommon.documentsUploaded'
                  )}
                </div>
              </div>
              <AccessWrapper
                claims={CLAIMS.CAN_DOWNLOAD_INSPECTION_ATTACHMENTS}
              >
                <Button
                  className="always-enabled"
                  onClick={downloadAll}
                  startIcon={
                    downloadAllLoading ? (
                      <Loader size={15} />
                    ) : (
                      <Download02Icon />
                    )
                  }
                  loading={downloadAllLoading}
                  disabled={!inspectionId}
                  variant={BUTTON_VARIANTS.OUTLINED}
                  sx={styles.downloadAll(isMobileView)}
                >
                  {translate('global.download')}
                </Button>
              </AccessWrapper>
            </div>
          </div>

          {inspections.length ? (
            <>
              <InspectionDocumentsComponent
                documents={inspections.map((inspection) => ({
                  id: inspection.inspectionSectionId,
                  attachments: inspection.inspectionAttachments,
                  name: inspection.stipulationDefinition?.name,
                  ...inspection,
                }))}
                loanApplicationId={loanApplicationId}
                loading={loading}
                setLoading={setLoading}
                reFetchDocuments={loadInspectionFiles}
                onUpload={(file, documentId) =>
                  uploadInspectionAttachment(dispatch, documentId, file)
                }
                userData={userData}
                updateUserNtpStatus={(result) =>
                  updateInspectionAttachmentStatus(
                    dispatch,
                    loanApplicationId,
                    result
                  )
                }
                loanApplicationStateId={crtStateOrder}
                isApproved={isInspectionApproved}
                isSubmitted={isInspectionSubmitted}
                viewOnlyStates={[
                  STATE_IDS.FinalInspectionDocsPendingApproval,
                  STATE_IDS.FinalInspectionDocsApproved,
                  STATE_IDS.FinalInspectionDocsReSubmitted,
                  STATE_IDS.FinalInspectionDocsRejected,
                  STATE_IDS.FinalInspectionFundsRequested,
                  STATE_IDS.FinalInspectionFundsGranted,
                ]}
                uploadFunctionSettings={(documentId) => ({
                  method: 'POST',
                  url: `${process.env.REACT_APP_HOST_LENDER_V3}/LoanApplication/inspection/upload-attachment`,
                  data: { inspectionSectionId: documentId },
                  cb: loadInspectionFiles,
                })}
                attachmentIdField="inspectionAttachmentId"
                canUploadAttachments={hasAccess(
                  userData,
                  CLAIMS.CAN_UPLOAD_INSPECTION_ATTACHMENTS
                )}
                canDeleteAttachments={hasAccess(
                  userData,
                  CLAIMS.CAN_DELETE_INSPECTION_ATTACHMENTS
                )}
                canDeleteAttachmentsAfterSubmit={hasAccess(
                  userData,
                  CLAIMS.CAN_DELETE_INSPECTION_ATTACHMENTS_AFTER_SUBMIT_FOR_APPROVAL
                )}
                canSeeDecisioningButtons={hasAccess(
                  userData,
                  CLAIMS.CAN_UPDATE_INSPECTION_ATTACHMENT_STATUS
                )}
              />
            </>
          ) : null}
        </div>

        <div style={{ marginTop: '16px' }}>
          {!isTabletView && _renderButtons()}

          <AccessWrapper claims={CLAIMS.CAN_VIEW_INSPECTION_COMMENTS}>
            <CommentsSection
              inspection
              comments={comments}
              onSubmit={submitComment}
              canComment={hasAccess(userData, CLAIMS.CAN_COMMENT_INSPECTION)}
              removeComment={removeComment}
              userId={userId}
              canRemoveNTPComments={hasAccess(
                userData,
                CLAIMS.CAN_REMOVE_INSPECTION_COMMENTS
              )}
            />
          </AccessWrapper>

          {isTabletView && _renderButtons()}
        </div>
      </div>
    </div>
  )
}

NTPInspection.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  inspections: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentState: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
}

export default NTPInspection
