import {
  STATE_ID_TO_NAME,
  STATE_ID_TO_NAME_COMMERCIAL,
  STATES_ORDER,
  STATES_ORDER_COMMERCIAL,
  STEPS_MAP,
  STEPS_MAP_COMMERCIAL,
} from '../constants'

/**
 * Compute possible steps for a Loan App based on the Possible States received from the BE + other small rules
 * @param {Array} possibleStates - future states in which the app can go
 * @param {Boolean} [isCommercialApp]
 * @param {Boolean} [isPtoNotMandatory]
 * @return {Array<Number>}
 */
export const computePossibleSteps = (
  possibleStates,
  isCommercialApp,
  isPtoNotMandatory
) => {
  const statesToSteps = getStatesToSteps({ isCommercialApp, isPtoNotMandatory })
  const stateIdToName = isCommercialApp
    ? STATE_ID_TO_NAME_COMMERCIAL
    : STATE_ID_TO_NAME

  const possibleSteps = new Set(
    possibleStates.map((stateId) => statesToSteps[stateIdToName[stateId]])
  )
  // Add custom Logic here for certain steps, if needed
  return Array.from(possibleSteps)
}

export const getStatesToSteps = ({ isCommercialApp, isPtoNotMandatory }) => {
  if (isCommercialApp) {
    return {
      ApplicationStarted: STEPS_MAP_COMMERCIAL.NEW_LOAN_APPLICATION,
      EvaluationFailed: STEPS_MAP_COMMERCIAL.SELECT_LOAN,
      LoanSelected: STEPS_MAP_COMMERCIAL.SELECT_LOAN,
      AgreementsAccepted: STEPS_MAP_COMMERCIAL.LENDER_AGREEMENT,
      ApplicationAuthorized: STEPS_MAP_COMMERCIAL.LENDER_AGREEMENT,
      CommercialDocumentationReview: STEPS_MAP_COMMERCIAL.COMMERCIAL_NTP,
      CommercialStipulationsApproved: STEPS_MAP_COMMERCIAL.CONGRATS,
      ApplicationExpired: STEPS_MAP.SELECT_LOAN,
    }
  } else {
    return {
      ApplicationStarted: STEPS_MAP.NEW_LOAN_APPLICATION,
      EvaluationFailed: STEPS_MAP.SELECT_LOAN,
      LoanSelected: STEPS_MAP.SELECT_LOAN,
      ConditionalApproval: STEPS_MAP.SELECT_LOAN,
      AgreementsAccepted: STEPS_MAP.LENDER_AGREEMENT,
      ApplicationAuthorized: STEPS_MAP.LENDER_AGREEMENT,
      DocumentsGenerated: STEPS_MAP.LOAN_DOCUMENTS,
      DocumentsSigned: STEPS_MAP.NTP,
      NTPDocsPendingApproval: STEPS_MAP.NTP,
      NTPDocsRejected: STEPS_MAP.NTP,
      NTPDocsReSubmitted: STEPS_MAP.NTP,
      NTPDocsApproved: STEPS_MAP.NTP,
      NTPFundsRequested: STEPS_MAP.NTP,
      NTPFundsGranted: STEPS_MAP.NTP,
      InstallCompleteDocsPendingApproval: STEPS_MAP.NTP,
      InstallCompleteDocsRejected: STEPS_MAP.NTP,
      InstallCompleteDocsReSubmitted: STEPS_MAP.NTP,
      InstallCompleteDocsApproved: STEPS_MAP.NTP,
      InstallCompleteFundsRequested: STEPS_MAP.NTP,
      InstallCompleteFundsGranted: STEPS_MAP.NTP_INSPECTION,
      FinalInspectionDocsPendingApproval: STEPS_MAP.NTP_INSPECTION,
      FinalInspectionDocsRejected: STEPS_MAP.NTP_INSPECTION,
      FinalInspectionDocsReSubmitted: STEPS_MAP.NTP_INSPECTION,
      FinalInspectionDocsApproved: STEPS_MAP.NTP_INSPECTION,
      FinalInspectionFundsRequested: STEPS_MAP.NTP_INSPECTION,
      FinalInspectionFundsGranted: !isPtoNotMandatory
        ? STEPS_MAP.PTO_SUBMISSION
        : STEPS_MAP.CONGRATS,
      PTODocsPendingApproval: !isPtoNotMandatory
        ? STEPS_MAP.PTO_SUBMISSION
        : STEPS_MAP.CONGRATS,
      PTODocsRejected: !isPtoNotMandatory
        ? STEPS_MAP.PTO_SUBMISSION
        : STEPS_MAP.CONGRATS,
      PTODocsApproved: !isPtoNotMandatory
        ? STEPS_MAP.PTO_SUBMISSION
        : STEPS_MAP.CONGRATS,
      PTOFundsRequested: !isPtoNotMandatory
        ? STEPS_MAP.PTO_SUBMISSION
        : STEPS_MAP.CONGRATS,
      PTOFundsGranted: STEPS_MAP.CONGRATS,
      ApplicationExpired: STEPS_MAP.SELECT_LOAN,
    }
  }
}

export const getStepsMap = ({ isCommercialApp, isPtoNotMandatory }) => {
  if (isCommercialApp) {
    return STEPS_MAP_COMMERCIAL
  } else {
    if (!isPtoNotMandatory) {
      return STEPS_MAP
    } else {
      const stepsWithoutPto = { ...STEPS_MAP }
      delete stepsWithoutPto.PTO_SUBMISSION
      return stepsWithoutPto
    }
  }
}

export const isAppNotFinished = ({
  currentState,
  isCommercialApp,
  isPtoNotMandatory,
}) => {
  const statesOrder = isCommercialApp ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  if (isCommercialApp) {
    return (
      statesOrder[currentState?.name] <
      STATES_ORDER_COMMERCIAL.CommercialStipulationsApproved
    )
  } else {
    if (!isPtoNotMandatory) {
      return statesOrder[currentState?.name] < STATES_ORDER.PTOFundsGranted
    } else {
      return (
        statesOrder[currentState?.name] <
        STATES_ORDER.InstallCompleteFundsGranted
      )
    }
  }
}
