import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { containers, assets, components } from '@ElementsCapitalGroup/enium-ui'
import useSWR from 'swr'
import {
  getAssociations,
  logout,
  rehydrateUserData,
} from 'modules/session/actions'
import GlobalModule from 'modules/global'
import { loadGlobalTranslations } from 'modules/translations'
import { useStore } from 'store'
import { useNavigationLinks } from 'components/navigation'
import { SelectRole } from 'components/select-role'
import { useNavigate } from 'react-router-dom'
import { SelectLanguage } from 'components/language-selection'
import useOutageMessages from 'modules/outage-messages'
import { hasAccess, updateAccessTokenIfExpired } from 'common/access'
import { CLAIMS } from 'common/claims'
import Cookies from 'js-cookie'
import { COOKIE_NAME } from 'common/constants'

const { PostAuthContainer } = containers
const { Collapse } = components
const {
  LogOutIcon,
  SwitchHorizontalIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  FileCheck01Icon,
  Globe02Icon,
} = assets

/**
 * This is the wrapper for all auth-pages
 * It constructs the basic page model for auth-pages and will also pre-fetch globally-required data

 * @param {Array<Node>|Node} children
 * @constructor
 */
function AuthWrapper({ children }) {
  const { banner } = useOutageMessages()
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const { userData } = state.session
  const { organizationId, organizationName, role } = userData

  const [loading, setLoading] = useState(true)
  const [isLoadingRefreshToken, setIsLoadingRefreshToken] = useState(false)
  const [isRolesListVisible, setIsRolesListVisible] = useState(false)
  const [isLanguageListVisible, setIsLanguageListVisible] = useState(false)
  const toggleIsRolesListVisible = () =>
    setIsRolesListVisible(!isRolesListVisible)
  const toggleLanguageListVisible = () =>
    setIsLanguageListVisible(!isLanguageListVisible)
  const { navLinks, bottomNavLinks } = useNavigationLinks(userData)
  const drawerMenuProps = {
    onMenuItemClick: (_, item) => navigate(item.url),
    menus: navLinks,
    bottomMenus: bottomNavLinks,
  }

  const userMenuProps = {
    role: `${role} - ${organizationName}`,
    fullName: `${userData.firstName} ${userData.lastName}`,
    collapsable: true,
    items: hasAccess(userData, CLAIMS.CAN_VIEW_NOTIFICATIONS)
      ? [
          {
            onClick: () => toggleIsRolesListVisible(),
            label: 'Roles',
            icon: <SwitchHorizontalIcon />,
            children: (
              <Collapse in={isRolesListVisible} timeout="auto" unmountOnExit>
                <div>
                  <SelectRole />
                </div>
              </Collapse>
            ),
            secondaryAction: !isRolesListVisible ? (
              <ChevronDownIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleIsRolesListVisible()}
              />
            ) : (
              <ChevronUpIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleIsRolesListVisible()}
              />
            ),
          },
          {
            label: 'Release Notes',
            icon: <FileCheck01Icon />,
            onClick: () => navigate('/release-notes'),
          },
          {
            label: 'Logout',
            icon: <LogOutIcon />,
            onClick: () => logout(dispatch),
          },
          {
            onClick: () => toggleLanguageListVisible(),
            label: 'Select Language',
            icon: <Globe02Icon />,
            children: (
              <Collapse in={isLanguageListVisible} timeout="auto">
                <div>
                  <SelectLanguage />
                </div>
              </Collapse>
            ),
            secondaryAction: !isLanguageListVisible ? (
              <ChevronDownIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleLanguageListVisible()}
              />
            ) : (
              <ChevronUpIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleLanguageListVisible()}
              />
            ),
          },
        ]
      : [
          {
            onClick: () => toggleIsRolesListVisible(),
            label: 'Roles',
            icon: <SwitchHorizontalIcon />,
            children: (
              <Collapse in={isRolesListVisible} timeout="auto" unmountOnExit>
                <div>
                  <SelectRole />
                </div>
              </Collapse>
            ),
            secondaryAction: !isRolesListVisible ? (
              <ChevronDownIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleIsRolesListVisible()}
              />
            ) : (
              <ChevronUpIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleIsRolesListVisible()}
              />
            ),
          },
          {
            label: 'Release Notes',
            icon: <FileCheck01Icon />,
            onClick: () => navigate('/release-notes'),
          },
          {
            label: 'Logout',
            icon: <LogOutIcon />,
            onClick: () => logout(dispatch),
          },
          {
            onClick: () => toggleLanguageListVisible(),
            label: 'Select Language',
            icon: <Globe02Icon />,
            children: (
              <Collapse in={isLanguageListVisible} timeout="auto">
                <div>
                  <SelectLanguage />
                </div>
              </Collapse>
            ),
            secondaryAction: !isLanguageListVisible ? (
              <ChevronDownIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleLanguageListVisible()}
              />
            ) : (
              <ChevronUpIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleLanguageListVisible()}
              />
            ),
          },
        ],
  }

  useEffect(() => {
    loadGlobalTranslations().finally(() => setLoading(false))
  }, [])

  const rehydrateUserStoreData = () => {
    rehydrateUserData(dispatch)
  }

  /** Rehydrate user store data on every window focus to maintain persistence between multiple browser tab */
  useSWR('rehydrateUserStore', rehydrateUserStoreData)

  const checkAuthStatus = async () => {
    await updateAccessTokenIfExpired(
      dispatch,
      setIsLoadingRefreshToken,
      userData
    )

    if (!isLoadingRefreshToken && Cookies.get(COOKIE_NAME)) {
      return getAssociations(dispatch).then((res) => {
        const associations = res?.associations
        if (!associations || associations.length === 0) {
          return false
        }

        const containsCurrentAssociation = associations.find(
          (item) =>
            item.organizationId?.toLowerCase() === organizationId?.toLowerCase()
        )
        return !!containsCurrentAssociation
      })
    }
  }

  /**
   * Fetch /me every REFRESH_INTERVAL ms and on window focus.
   * If there are no associations or the current association does no longer exist, log the user out.
   */
  useSWR('me', checkAuthStatus, {
    refreshInterval: 60000,
  })

  return (
    <PostAuthContainer
      drawerMenuProps={drawerMenuProps}
      userMenuProps={userMenuProps}
      onLogoClick={() => navigate('/')}
      alertProps={
        banner?.title
          ? {
              severity: 'info',
              title: banner.title,
              description: banner.text,
              isDismissible: banner.dismissible,
            }
          : null
      }
    >
      <GlobalModule />
      {!loading && <main>{children}</main>}
    </PostAuthContainer>
  )
}

AuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  requiredClaims: PropTypes.array,
}

export default AuthWrapper
