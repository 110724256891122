import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components, colors, assets } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'
import GeneralDetails from 'modules/loan-application/create-loan-app/general-details'
import Checkbox from 'components/checkbox'
import {
  ADDRESS_DETAILS,
  APPLICANT_TYPE,
  DESKTOP_BREAKPOINT,
  LOAN_PAGE_ERROR_MAP_WITH_EMPLOYER_FIELDS,
  USER_GENERAL_DETAILS,
} from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { validate, validateSSN } from 'components/validator'
import { useStore } from 'store'
import {
  getCitizenships,
  getCommunicationMethods,
  getEmploymentStatuses,
  getMLAs,
  getResidencyOccupationTypes,
  addCoBorrower,
  getAdditionalIncomeTypes,
} from 'modules/loan-application/actions'
import AddressDetails from 'modules/loan-application/create-loan-app/address-details'
import AdditionalIncomeDetails from 'modules/loan-application/create-loan-app/additional-income-details'
import { formatApplicantForServer } from '../../utils'

const {
  Dialog,
  FeaturedIcon,
  FeaturedIconColors,
  FeaturedIconVariants,
  FeaturedIconSizes,
} = components

const { AlertCircleIcon, TrashIcon, PlusIcon } = assets

const AddCoBorrowerModal = ({
  dispatch,
  loanApplicationId,
  loanFormData,
  isOpen,
  onClose,
  handleGetSelfServiceCriterias,
}) => {
  const { state } = useStore()
  const { userData } = state.session
  const { t: translate } = useTranslation()
  const [coBorrower, setCoBorrower] = useState({
    ...USER_GENERAL_DETAILS,
  })
  const [coBorrowerAddress, setCoBorrowerAddress] = useState({
    ...ADDRESS_DETAILS,
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ coBorrower: {} })
  const [checked, setIsChecked] = useState(false)
  const [citizenships, setCitizenships] = useState([])
  const [militaryAffiliation, setMilitaryAffiliation] = useState([])
  const [employmentStatuses, setEmploymentStatuses] = useState([])
  const [residencyOccupation, setResidencyOccupation] = useState([])
  const [additionalIncomeTypes, setAdditionalIncomeTypes] = useState([])
  const [communicationMethods, setCommunicationMethods] = useState([])
  const serviceAddress = loanFormData.serviceAddress
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const COBORROWER_LOAN_PAGE_ERROR_MAP = coBorrower.hasAdditionalIncome
    ? (({ loanAmount, ...o }) => o)(LOAN_PAGE_ERROR_MAP_WITH_EMPLOYER_FIELDS)
    : (({ additionalIncomeTypeId, additionalIncomeAmount, loanAmount, ...o }) =>
        o)(LOAN_PAGE_ERROR_MAP_WITH_EMPLOYER_FIELDS)

  /** On mount, fetch data for dropdowns */
  useEffect(() => {
    const promises = []
    setLoading(true)
    if (!communicationMethods.length) {
      promises.push(getCommunicationMethods().then(setCommunicationMethods))
    }
    if (!employmentStatuses.length) {
      promises.push(getEmploymentStatuses().then(setEmploymentStatuses))
    }
    if (!additionalIncomeTypes.length) {
      promises.push(getAdditionalIncomeTypes().then(setAdditionalIncomeTypes))
    }
    if (!citizenships.length) {
      promises.push(getCitizenships().then(setCitizenships))
    }
    if (!militaryAffiliation.length) {
      promises.push(getMLAs().then(setMilitaryAffiliation))
    }
    if (!residencyOccupation.length) {
      promises.push(getResidencyOccupationTypes().then(setResidencyOccupation))
    }
    Promise.all(promises).finally(() => setLoading(false))
  }, [])

  const handleOnClose = (event, reason) => {
    // Disable on background click for closing the Dialog
    if (reason !== 'closeButtonClicked') {
      return
    }
    onClose()
  }

  const handleSubmit = () => {
    if (validateForm()) {
      const serverPayload = formatApplicantForServer(
        coBorrower,
        coBorrowerAddress,
        {},
        APPLICANT_TYPE.COBORROWER
      )
      setLoading(true)
      addCoBorrower(dispatch, loanApplicationId, serverPayload).finally(() => {
        handleGetSelfServiceCriterias()
        setLoading(false)
      })
    }
  }

  const validateForm = () => {
    let isValid = true
    const errors = { coBorrower: {} }
    const [isCoBorrowerValid, coBorrowerErrors] = validate(
      COBORROWER_LOAN_PAGE_ERROR_MAP,
      {
        ...coBorrower,
        ...(coBorrower.sameWithServiceAddress
          ? { ...serviceAddress }
          : { ...coBorrowerAddress }),
      }
    )
    // Validate CoBorrower's SSN
    if (!validateSSN(coBorrower.last4SSN, true)) {
      coBorrowerErrors.last4SSN = translate(
        'loanApplication.step1.errors.invalidSsnLength'
      )
    }
    if (!isCoBorrowerValid) {
      errors.coBorrower = coBorrowerErrors
      isValid = false
    }
    setErrors(errors)
    return isValid
  }

  return (
    <Dialog
      open={isOpen}
      disableEscapeKeyDown={true}
      onClose={handleOnClose}
      title={translate('loanApplication.common.coborrowerDetails')}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '1060px',
          '& .MuiDialogTitle-root': {
            alignItems: 'center',
            fontSize: '1.125rem',
          },
        },
      }}
      actions={
        <>
          <Button
            onClick={onClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            disabled={loading}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button disabled={!checked} loading={loading} onClick={handleSubmit}>
            {translate('loanApplication.blocked.addCoBorrowerCta')}
          </Button>
        </>
      }
      actionsProps={{
        sx: {
          paddingTop: '8px',
          width: '100%',
          justifyContent: 'space-between',
        },
      }}
    >
      <div>
        <div
          style={{
            borderRadius: '8px',
            border: `1px solid ${colors.yellow[700]}`,
            background: colors.yellow[50],
            width: '100%',
            display: 'flex',
            padding: '16px',
            alignItems: 'center',
          }}
        >
          <FeaturedIcon
            icon={<AlertCircleIcon />}
            color={FeaturedIconColors.WARNING}
            size={FeaturedIconSizes.XL}
            variant={FeaturedIconVariants.RIPPLE}
          />
          <div style={{ marginLeft: '10px' }}>
            <div
              style={{
                color: colors.yellow[600],
                fontWeight: 600,
                fontSize: '1.125rem',
              }}
            >
              {translate('loanApplication.blocked.consequencesTitle')}
            </div>
            <div
              style={{
                fontSize: '0.875rem',
                color: colors.grey[900],
                width: '100%',
                marginTop: '4px',
              }}
            >
              {translate('loanApplication.blocked.consequencesText')}
            </div>
          </div>
        </div>
        <GeneralDetails
          generalDetails={coBorrower}
          setGeneralDetails={setCoBorrower}
          userData={userData}
          coBorrower={true}
          citizenships={citizenships}
          employmentStatuses={employmentStatuses}
          militaryAffiliation={militaryAffiliation}
          residencyOccupation={residencyOccupation}
          communicationMethods={communicationMethods}
          errors={errors}
          setErrors={setErrors}
          onChangeHideEmploymentFields={() => {}}
          onChangeHideIncomeField={() => {}}
          areInputsDisabled={loading}
        />
        <div style={{ marginTop: '24px' }}>
          <Button
            startIcon={
              coBorrower.hasAdditionalIncome ? <TrashIcon /> : <PlusIcon />
            }
            variant={
              isTabletView ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.TEXT
            }
            color={
              isTabletView ? BUTTON_COLORS.SECONDARY : BUTTON_COLORS.PRIMARY
            }
            sx={{
              marginRight: { xs: 0, sm: '12px', lg: 0 },
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            disabled={loading}
            onClick={() =>
              setCoBorrower((prevInputData) => {
                return {
                  ...prevInputData,
                  hasAdditionalIncome: !coBorrower.hasAdditionalIncome,
                }
              })
            }
          >
            {!coBorrower.hasAdditionalIncome
              ? translate('loanApplication.step1.addAdditionalIncome')
              : translate('loanApplication.step1.removeAdditionalIncome')}
          </Button>
          <Button
            startIcon={
              !coBorrower.sameWithServiceAddress ? <TrashIcon /> : <PlusIcon />
            }
            variant={
              isTabletView ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.TEXT
            }
            color={
              isTabletView ? BUTTON_COLORS.SECONDARY : BUTTON_COLORS.PRIMARY
            }
            sx={{
              marginRight: { xs: 0, sm: '12px', lg: 0 },
              marginTop: { xs: '16px', sm: 0 },
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            disabled={loading}
            onClick={() => {
              const isChecked = coBorrower.sameWithServiceAddress

              if (isChecked) {
                // if checked reset borrower address, so addressId will also be null
                setCoBorrowerAddress({ ...ADDRESS_DETAILS })
              } else {
                // if unchecked set it back to service address
                setCoBorrowerAddress({ ...serviceAddress })
              }
              setCoBorrower((prevInputData) => {
                return {
                  ...prevInputData,
                  sameWithServiceAddress: !isChecked,
                }
              })
            }}
          >
            {!coBorrower.sameWithServiceAddress
              ? translate('loanApplication.step1.removeBillingAddress')
              : translate('loanApplication.step1.addBillingAddress')}
          </Button>
        </div>

        {!coBorrower.sameWithServiceAddress ? (
          <div style={{ marginTop: '20px' }}>
            <AddressDetails
              addressDetails={coBorrowerAddress}
              setAddressDetails={setCoBorrowerAddress}
              errors={errors}
              setErrors={setErrors}
              coBorrower={true}
              hasSameAddress={coBorrower.sameWithServiceAddress}
              areInputsDisabled={loading}
            />
          </div>
        ) : null}

        {coBorrower.hasAdditionalIncome && (
          <div className="applicant-info" style={{ marginTop: '20px' }}>
            <AdditionalIncomeDetails
              generalDetails={coBorrower}
              setGeneralDetails={setCoBorrower}
              errors={errors}
              setErrors={setErrors}
              coBorrower={true}
              additionalIncomeTypes={additionalIncomeTypes}
              areInputsDisabled={loading}
            />
          </div>
        )}

        <div style={{ marginTop: '24px' }}>
          <Checkbox
            size={'medium'}
            label={'Co-Borrower agrees to the Terms & Conditions'}
            checked={checked}
            onClick={() => setIsChecked(!checked)}
            disabled={loading}
            labelProps={{ fontWeight: 500 }}
          />
        </div>
      </div>
    </Dialog>
  )
}

AddCoBorrowerModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  loanFormData: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleGetSelfServiceCriterias: PropTypes.func.isRequired,
}

export default AddCoBorrowerModal
