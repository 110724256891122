import React from 'react'
import { useTranslation } from 'react-i18next'
import { components, colors, assets } from '@ElementsCapitalGroup/enium-ui'
import { useNavigate } from 'react-router-dom'

const { Dialog, IconButton, Divider } = components

const { MessageSquareIcon, PhoneIcon } = assets

const ContactModal = () => {
  const navigate = useNavigate()
  const { t: translate } = useTranslation()

  return (
    <>
      <Dialog
        open={true}
        sx={{
          backdropFilter: 'blur(10px)',
        }}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '600px',
            '& .MuiDialogTitle-root': {
              alignItems: 'center',
              fontSize: '1.125rem',
              paddingBottom: '24px',
            },
          },
        }}
        title={translate('loanApplication.contactUs.title')}
        disableEscapeKeyDown={true}
        onClose={() => {
          navigate('/applications')
        }}
      >
        <div className="loan-blocked__text" style={{ fontSize: '1rem' }}>
          {translate('loanApplication.contactUs.description')}
        </div>

        <div
          className="loan-blocked__option__title"
          style={{ marginTop: '32px' }}
        >
          {translate('loanApplication.contactUs.options')}
        </div>

        <div className="loan-blocked__option loan-blocked__option--contact">
          <div>
            <IconButton
              size="48px"
              variant={'outlined'}
              className="loan-blocked__option__svg"
            >
              <PhoneIcon sx={{ color: colors.grey[700] }} />
            </IconButton>
          </div>
          <div>
            <div className="loan-blocked__option__title">
              {translate('loanApplication.contactUs.phoneNumber')}
            </div>
          </div>
          <div className="loan-blocked__option__action">
            <a
              href={translate('loanApplication.contactUs.phoneNumberTelValue')}
              style={{ color: colors.blue[500], fontWeight: 600 }}
            >
              {translate('loanApplication.contactUs.phoneNumberValue')}
            </a>{' '}
          </div>
        </div>
        <Divider />
        <div className="loan-blocked__option loan-blocked__option--contact">
          <div>
            <IconButton
              size="48px"
              variant={'outlined'}
              className="loan-blocked__option__svg"
            >
              <MessageSquareIcon sx={{ color: colors.grey[700] }} />
            </IconButton>
          </div>
          <div>
            <div className="loan-blocked__option__title">
              {translate('loanApplication.contactUs.textUs')}
            </div>
          </div>
          <div className="loan-blocked__option__action">
            <a
              href={translate(
                'loanApplication.contactUs.textPhoneNumberTelValue'
              )}
              style={{ color: colors.blue[500], fontWeight: 600 }}
            >
              {translate('loanApplication.contactUs.textPhoneNumberValue')}
            </a>{' '}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ContactModal
