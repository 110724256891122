import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextField from 'components/input'
import { formatInUSFormat } from 'common/number'
import { useStore } from 'store'
import { styles } from '../ntp/style'

const { Dialog } = components

const GrantFundsModal = ({
  showGrantModal,
  setShowGrantModal,
  loading,
  onGrantFunds,
  titleLabel,
  grantBtnLabel,
  fundingInputLabel,
  isPto,
}) => {
  const { state } = useStore()
  const {
    initialFundingAmount = 0,
    finalFundingAmount = 0,
    finalInspectionFundingAmount = 0,
    borrowerGeneralDetails,
  } = state.flow.loanFormData
  const { loanAmount } = borrowerGeneralDetails
  const { t: translate } = useTranslation()
  const [fundingAmount, setFundingAmount] = useState('')
  const [fundingAmountError, setFundingAmountError] = useState(null)

  let amountLeftToBeFunded = Number(
    isPto
      ? loanAmount -
          initialFundingAmount -
          finalFundingAmount -
          finalInspectionFundingAmount
      : loanAmount - initialFundingAmount - finalFundingAmount - fundingAmount
  ).toFixed(2)
  if (amountLeftToBeFunded <= 0) {
    amountLeftToBeFunded = 0
  }

  /** Validate & Grant funds */
  const grantFundsClicked = () => {
    if (!isPto) {
      if (!fundingAmount) {
        return setFundingAmountError(
          translate(
            'loanApplication.ntpStepsCommon.errors.fundingAmountRequired'
          )
        )
      }
      if (
        fundingAmount >
        parseFloat(
          Number(
            loanAmount - initialFundingAmount - finalFundingAmount
          ).toFixed(2)
        )
      ) {
        return setFundingAmountError(
          translate('loanApplication.ntpStepsCommon.errors.totalAmountExceded')
        )
      }

      onGrantFunds(fundingAmount)
    } else {
      onGrantFunds(fundingAmount)
    }
  }

  return (
    <Dialog
      title={titleLabel}
      open={showGrantModal}
      onClose={() => setShowGrantModal(false)}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '600px',
        },
      }}
      actions={
        <>
          <Button
            onClick={() => setShowGrantModal(false)}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button onClick={grantFundsClicked} disabled={loading}>
            {grantBtnLabel}
          </Button>
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          label={fundingInputLabel}
          type="currency"
          fixedDecimalScale={true}
          disabled={!!isPto}
          value={
            isPto
              ? Number(
                  loanAmount -
                    initialFundingAmount -
                    finalFundingAmount -
                    finalInspectionFundingAmount
                ).toFixed(2) - 0 // Subtracted 0 because this fixes the -0.00 case
              : fundingAmount
          }
          onChange={(val) => {
            setFundingAmount(val)
            setFundingAmountError(null)
          }}
          max={loanAmount}
          validate={isPto ? true : () => fundingAmountError}
          style={{ width: '48%' }}
        />
        <TextField
          label={translate(
            `loanApplication.ntpStepsCommon.amountLeftToBeFunded`
          )}
          disabled={true}
          type="currency"
          fixedDecimalScale={true}
          value={amountLeftToBeFunded}
          onChange={() => {}}
          style={{ width: '48%' }}
        />
      </div>

      <div>
        <div style={styles.totalLoanAmountLabel}>
          {translate('loanApplication.ntpStepsCommon.totalLoanAmount')}
        </div>
        <div>{`$ ${formatInUSFormat(loanAmount)}`}</div>
      </div>
    </Dialog>
  )
}

GrantFundsModal.propTypes = {
  showGrantModal: PropTypes.bool.isRequired,
  setShowGrantModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onGrantFunds: PropTypes.func.isRequired,
  titleLabel: PropTypes.string.isRequired,
  grantBtnLabel: PropTypes.string.isRequired,
  fundingInputLabel: PropTypes.string.isRequired,
  isPto: PropTypes.bool,
}

export default GrantFundsModal
