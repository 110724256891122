import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import {
  dateFormatServer,
  DESKTOP_BREAKPOINT,
  dobDateFormat,
  LARGE_MOBILE_BREAKPOINT,
} from 'common/constants'
import { compareIgnoreCase } from 'common/utils'
import { CLAIMS } from 'common/claims'
import { AccessWrapper } from 'common/access'
import { useMediaQuery } from 'common/hooks'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Table from 'components/table'
import { _isEnvelopeSigned } from './utils'
import { styles } from './style'
import { ENVELOPE_STATUS } from '../constants'

const { Edit03Icon, FileAttachment02Icon, MailIcon, DownloadIcon, XCloseIcon } =
  assets
const { Divider, Dialog, createColumnHelper, Chip, Tooltip } = components
const columnHelper = createColumnHelper()

const EnvelopeTable = ({
  envelope,
  onEmailClicked,
  onDownloadClicked,
  onVoidClicked,
  viewOnly,
}) => {
  const { t: translate } = useTranslation()
  const [sortedData, setSortedData] = useState([])
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [voidDocumentId, setVoidDocumentId] = useState(null)
  const tableRef = useRef()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${LARGE_MOBILE_BREAKPOINT}px)`)

  const _renderDocumentButton = (borrower) => {
    const isSigned = _isEnvelopeSigned(borrower)
    const claim = isSigned
      ? CLAIMS.CAN_VIEW_DOCUMENTS
      : CLAIMS.CAN_SIGN_DOCUMENTS
    return (
      <AccessWrapper claims={claim}>
        <Button
          variant={BUTTON_VARIANTS.CONTAINED}
          color={BUTTON_COLORS.SECONDARY}
          sx={{
            width: isMobileView ? '100%' : isTabletView ? '220px' : '240px',
          }}
          startIcon={isSigned ? <FileAttachment02Icon /> : <Edit03Icon />}
          onClick={() => {
            if (isSigned) {
              window.open(borrower.url, '_blank')
            } else {
              window.open(borrower.url, '_self')
            }
          }}
          className={isSigned ? 'always-enabled' : ''}
        >
          {isSigned
            ? translate('loanApplication.step4.viewDocuments')
            : `${translate('loanApplication.step4.signDocuments')} ${
                borrower.name
              }`}
        </Button>
      </AccessWrapper>
    )
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('recipient', {
        header: 'Recipient',
        enableSorting: true,
        size: 'auto',
        cell: ({ row }) => (
          <div style={styles.recipientName}>
            {row.original.name}
            <div style={styles.recipientEmail}>{row.original.email}</div>
          </div>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        size: isTabletView ? 120 : 'auto',
        enableSorting: true,
        cell: ({ row }) => (
          <Chip
            size="small"
            color={STATUS_TO_CHIP_COLOR[row.original.status.id]}
            label={row.original.status.name}
            style={STATUS_TO_CHIP_STYLE[row.original.status.id] || {}}
          ></Chip>
        ),
      }),
      columnHelper.accessor('dateSent', {
        header: 'Date Sent',
        size: isTabletView ? 130 : 'auto',
        enableSorting: true,
        cell: ({ row }) => moment(row.original.dateSent).format(dobDateFormat),
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        size: isTabletView ? 260 : 'auto',
        cell: ({ row }) => _renderDocumentButton(row.original),
      }),
    ],
    []
  )

  /** On envelope change, re-calculate table data */
  useEffect(() => {
    const data = [envelope.borrower, envelope.coBorrower]
      .map((borrower) => {
        if (!borrower) {
          return null
        }
        return {
          name: `${borrower.firstName} ${borrower.lastName}`,
          email: borrower.email,
          dateSent: envelope.dateSent || new Date(),
          status: borrower.status,
          url: borrower.url,
        }
      })
      .filter((el) => !!el)

    setSortedData(data)
  }, [envelope])

  const onSortByChange = (key, value) => {
    tableRef.current?.setPageIndex(0)
    const updated = [...sortedData].sort((a, b) => {
      const orderSign = value === 'ASC' ? 1 : -1
      if (key === 'status') {
        const order = a.status.id < b.status.id ? -1 : 1
        return order * -1
      } else if (key === 'recipient') {
        const order = compareIgnoreCase(a.name, b.name)
        return order * orderSign
      } else if (key === 'dateSent') {
        const aDate = moment(a.dateSent).format(dateFormatServer)
        const bDate = moment(b.dateSent).format(dateFormatServer)
        const order = aDate < bDate ? -1 : 1
        return order * orderSign
      }
      return 0
    })
    setSortedData(updated)
  }

  const _renderEnvelopeButtons = () => {
    return (
      <div style={styles.envelopeButtonWrapper}>
        <AccessWrapper claims={CLAIMS.CAN_SEND_DOCUMENTS}>
          <Tooltip
            title={
              !_isEnvelopeSigned(envelope)
                ? translate('userDetails.email')
                : translate('loanApplication.step4.sendCompletedEnvelopes')
            }
          >
            <div
              style={{
                ...styles.envelopeButton,
                ...(isMobileView && { marginLeft: '16px' }),
              }}
              onClick={() => {
                setEmailLoading(true)
                onEmailClicked().finally(() => setEmailLoading(false))
              }}
              className={emailLoading ? 'disabled' : 'always-enabled'}
            >
              <MailIcon sx={{ width: '20px', height: '20px' }} />
            </div>
          </Tooltip>
        </AccessWrapper>

        <AccessWrapper claims={CLAIMS.CAN_VIEW_DOCUMENTS}>
          <Tooltip title={translate('loanApplication.step4.downloadDocuments')}>
            <div
              style={{
                ...styles.envelopeButton,
                ...(isMobileView && { marginLeft: '16px' }),
              }}
              className={downloadLoading ? 'disabled' : 'always-enabled'}
              onClick={() => {
                setDownloadLoading(true)
                onDownloadClicked().finally(() => setDownloadLoading(false))
              }}
            >
              <DownloadIcon sx={{ width: '20px', height: '20px' }} />
            </div>
          </Tooltip>
        </AccessWrapper>

        <AccessWrapper claims={CLAIMS.CAN_VOID_DOCUMENTS}>
          <Tooltip title={translate('loanApplication.step4.void')}>
            <div
              style={{
                ...styles.envelopeButton,
                ...(isMobileView && { marginLeft: '16px' }),
              }}
              className={viewOnly ? 'disabled' : ''}
              onClick={() => setVoidDocumentId(envelope.envelopeId)}
            >
              <XCloseIcon sx={{ width: '20px', height: '20px' }} />
            </div>
          </Tooltip>
        </AccessWrapper>
      </div>
    )
  }

  const _renderMobileView = () => {
    return (
      <div style={{ background: 'white', marginBottom: '24px' }}>
        <Divider />
        <div className="align-center" style={styles.mobileCardHeader}>
          <div>{envelope.envelopeName}</div>
          {_renderEnvelopeButtons()}
        </div>
        {sortedData.map((borrower, key) => (
          <React.Fragment key={key}>
            <Divider />
            <div style={styles.mobileCardContent}>
              <div className="align-center">
                <div>
                  <div style={{ margin: '8px 0 4px' }}>Recipient</div>
                  <div style={{ color: 'black', fontSize: '0.875rem' }}>
                    {borrower.name}
                  </div>
                  <span style={{ fontWeight: 400 }}>{borrower.email}</span>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Chip
                    size="small"
                    color={STATUS_TO_CHIP_COLOR[borrower.status.id]}
                    label={borrower.status.name}
                    style={STATUS_TO_CHIP_STYLE[borrower.status.id] || {}}
                  ></Chip>
                </div>
              </div>
              <div className="align-center">
                <div>
                  <div style={{ margin: '8px 0 4px' }}>Date Sent</div>
                  <div style={{ color: 'black', fontSize: '0.875rem' }}>
                    {moment(borrower.dateSent).format(dobDateFormat)}
                  </div>
                </div>
              </div>
              <div>{_renderDocumentButton(borrower)}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <>
      {!isMobileView ? (
        <Table
          ref={tableRef}
          title={envelope.envelopeName}
          columns={columns}
          data={sortedData}
          onSortingChange={onSortByChange}
          style={{ marginBottom: '24px' }}
          toolbarRightContent={_renderEnvelopeButtons()}
        />
      ) : (
        _renderMobileView()
      )}

      {/* Confirm Voiding the document */}
      <Dialog
        open={!!voidDocumentId}
        onClose={() => setVoidDocumentId(null)}
        title={translate('loanApplication.step3.areYouSure')}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '500px',
            '& .MuiDialogTitle-root': {
              padding: '24px 24px 0',
              fontSize: '1.125rem',
            },
            '& .MuiDialogActions-root': {
              paddingTop: '0',
            },
          },
        }}
        actionsProps={{
          sx: {
            width: '100%',
            justifyContent: 'space-between',
            '& button': {
              width: '50%',
            },
          },
        }}
        actions={
          <>
            <Button
              onClick={() => setVoidDocumentId(null)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button
              onClick={() => {
                onVoidClicked(voidDocumentId)
                setVoidDocumentId(null)
              }}
            >
              {translate('buttons.confirm')}
            </Button>
          </>
        }
      >
        <div
          className="delete-modal__question"
          style={{ marginTop: '20px', fontSize: '0.875rem' }}
        >
          You are about to void <strong>{envelope.envelopeName}</strong>. Are
          you sure you want to proceed?
        </div>
      </Dialog>
    </>
  )
}

const STATUS_TO_CHIP_COLOR = {
  [ENVELOPE_STATUS.CREATED]: 'primary',
  [ENVELOPE_STATUS.SIGNED]: 'success',
  [ENVELOPE_STATUS.COMPLETED]: 'success',
  [ENVELOPE_STATUS.DECLINED]: 'error',
}

const STATUS_TO_CHIP_STYLE = {
  [ENVELOPE_STATUS.SENT]: {
    color: '#C11574',
    background: '#FDF2FA',
  },
}

EnvelopeTable.propTypes = {
  envelope: PropTypes.object.isRequired,
  onEmailClicked: PropTypes.func.isRequired,
  onDownloadClicked: PropTypes.func.isRequired,
  onVoidClicked: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
}

export default EnvelopeTable
