import Api from 'easy-fetch-api'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

import { compareIgnoreCase } from 'common/utils'
import { ORGANIZATION_TYPE_IDS } from 'common/constants'
import { fromLocalOrgToServerOrg, fromServerOrgToLocalOrg } from './helpers'
import { PORTAL_ROLE_TYPES } from '../admin/constants'

export const ACTIONS = {
  SET_ALL_ORGANIZATIONS: 'orgManagement.setAllOrgs',
  SET_ORGANIZATIONS: 'orgManagement.setOrganizations',
  SET_ORGANIZATIONS_TREE: 'orgManagement.setOrganizationsTree',
  SET_TYPES: 'orgManagement.setTypes',
  SET_STATUSES: 'orgManagement.setStatuses',
  SET_ORG_NAV_HISTORY: 'orgManagement.setOrgNavHistory',

  SET_LENDERS: 'orgManagement.setLenders',
  SET_FICO_VERSIONS: 'orgManagement.setFicoVersions',
  SET_LENDER_ORGANIZATIONS: 'orgManagement.setLenderOrganizations',

  SET_ROLES: 'orgManagement.setRoles',
}

/** Get the full information about an organization by guid */
export const getOrganizationByGuid = (organizationGuid) =>
  Api.get({
    url: `/Organizations/${organizationGuid}`,
  })
    .then(fromServerOrgToLocalOrg)
    .catch(console.error)

/**
 * Get list of organizations - minimal data
 * @param {function} [dispatch] - optional; if not provided, the function will return the promise
 * @param {object} [query]
 * @return {Promise<{organizationUnits: Array, itemCount: number}>|[]}
 */
export const getOrganizations = (
  dispatch,
  query = { pageNumber: 0, itemsPerPage: 20 }
) => {
  return Api.get({
    url: '/Organizations/list-all-basic',
    query,
  })
    .then((res) => {
      const { organizationUnits, itemCount } = res
      dispatch?.({
        type: ACTIONS.SET_ORGANIZATIONS,
        organizationUnits,
        itemCount,
      })
      return { organizationUnits, itemCount }
    })
    .catch((err) => {
      console.error(err)
      dispatch?.({
        type: ACTIONS.SET_ORGANIZATIONS,
        organizationUnits: [],
        itemCount: 0,
      })
      return []
    })
}

/** Get list of all organizations with minimal data (GUID & Name) */
export const getAllOrganizations = (dispatch) => {
  return Api.get({
    url: '/Organizations/list-basic-all',
  })
    .then((res) => {
      const orgs = res?.organizationUnits || []
      const sorted = orgs
        .filter(
          (org) => org.status.name === 'Active' || org.status.name === 'Pending'
        )
        .sort((a, b) => compareIgnoreCase(a.name, b.name))
      dispatch &&
        dispatch({ type: ACTIONS.SET_ALL_ORGANIZATIONS, data: sorted })
      return sorted
    })
    .catch(console.error)
}

/** Get tree structure of all organizations (top-down) starting from the root org available to the current user */
export const getOrganizationsTree = (dispatch) => {
  return Api.get({
    url: '/Organizations/flatten-organization-tree',
  })
    .then((res) => {
      const tree = res.map((el) => ({
        guid: el.guid,
        name: el.name,
        parentId: el.parentId,
        isRoot: el.isRoot,
      }))
      dispatch({
        type: ACTIONS.SET_ORGANIZATIONS_TREE,
        data: tree,
      })
      return tree
    })
    .catch(console.error)
}

export const getTypes = (dispatch) =>
  Api.get({ url: '/Organizations/types' })
    .then((types) => dispatch({ type: ACTIONS.SET_TYPES, data: types }))
    .catch(console.error)

export const getStatuses = (dispatch) =>
  Api.get({ url: '/Organizations/organization-statuses' })
    .then((res) => dispatch({ type: ACTIONS.SET_STATUSES, data: res }))
    .catch(console.error)

export const getAssociatedLendersPriority = (orgId) => {
  return Api.get({
    url: `/Organizations/${orgId}/associated-lenders`,
  }).catch(console.error)
}
export const updateLendersPriority = (newPriorities) =>
  Api.put({
    url: '/Organizations/set-priority-lenders',
    data: { organizationAssociatedLenders: newPriorities },
  })

export const addOrganization = (orgToAdd) => {
  const isLender = orgToAdd.type?.id === ORGANIZATION_TYPE_IDS.LENDER
  const data = fromLocalOrgToServerOrg(orgToAdd)
  if (isLender) {
    return Api.post({
      url: '/Organizations/create-lender-organization',
      data,
    }).catch(console.error)
  }

  return Api.post({
    url: '/Organizations/create-organization',
    headers: {
      'x-requestid': orgToAdd.guid,
    },
    data,
  }).catch(console.error)
}

export const editOrganization = (orgData) => {
  const isLender = orgData.type?.id === ORGANIZATION_TYPE_IDS.LENDER
  const data = fromLocalOrgToServerOrg(orgData)
  if (isLender) {
    return Api.put({
      url: '/Organizations/update-lender-organization',
      data,
    })
  }

  return Api.put({
    url: '/Organizations',
    headers: { 'x-requestid': orgData.guid },
    data,
  })
}

/** Delete Organization */
export const deleteOrganization = (dispatch, id) =>
  Api.delete({
    url: `/Organizations/${id}`,
    headers: { 'x-requestid': id },
  })
    .then((response) => {
      if (response) {
        showNotification(dispatch, {
          title: `Successfully deleted ${response.name}`,
        })
      }
      return response
    })
    .catch((err) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while deleting the organization. ${err.title}`,
      })
    })

/**
 * Get all lenders.
 * There are 2 different entities lender and lender organizations. Every time an organization of type lender is created
 * a coresponding lender entity is created too.
 * They differ in properties.
 */
export const fetchLenders = (dispatch) =>
  Api.get({ url: '/Organizations/list-lenders-basic' }).then((response) => {
    const data = response.lenderOrganizations
      .sort((a, b) => compareIgnoreCase(a.name, b.name))
      .map((lender) => ({
        guid: lender.lenderId,
        organizationId: lender.organizationId,
        name: lender.name,
      }))
    dispatch({ type: ACTIONS.SET_LENDERS, data })
  })

export const getFicoVersions = (dispatch) =>
  Api.get({ url: '/Lender/fico-versions' }).then((ficoVersions) => {
    dispatch({ type: ACTIONS.SET_FICO_VERSIONS, ficoVersions })
  })

/**
 * Get all organizations of type lender.
 * There are 2 different entities lender and lender organizations. Every time an organization of type lender is created
 * a coresponding lender entity is created too.
 * They differ in properties.
 */
export const fetchLenderOrganizations = (dispatch) =>
  Api.get({
    url: '/Organizations/lender-organizations',
    query: { itemsPerPage: 2000 },
  })
    .then((res) => {
      const lendersOrganizations = res?.lenderOrganizations || []
      const data = lendersOrganizations.sort((a, b) =>
        compareIgnoreCase(a.name, b.name)
      )
      dispatch({ type: ACTIONS.SET_LENDER_ORGANIZATIONS, data })
    })
    .catch(console.error)

/**
 * Get List of Users for an Organization
 */

export const getUsersMinimalData = (organizationId, otherFilters = {}) => {
  const query = { ...otherFilters }
  if (organizationId) {
    query.OrganizationId = organizationId
  }

  return Api.get({ url: '/Users/list-basic', query }).catch((err) => {
    console.error(err)
    return []
  })
}

export const getRoles = (dispatch) => {
  Api.get({
    url: `/Roles`,
    query: {
      RoleTypeId: PORTAL_ROLE_TYPES.join(','),
    },
  }).then((roles) => {
    dispatch({
      type: ACTIONS.SET_ROLES,
      data: roles,
    })
  })
}

export const sendResetPasswordEmail = (userEmail) => {
  return Api.post({
    url: `/Users/credentials/begin-reset-password`,
    data: { emailAddress: userEmail },
  })
}

export const sendEmailUpdateEmail = (userId, newEmailAddress, dispatch) => {
  const payload = {
    userGuid: userId,
    newEmailAddress: newEmailAddress,
    commandType: 0,
  }

  return Api.post({
    url: '/Users/email-update',
    data: payload,
  })
    .then((res) => {
      showNotification(dispatch, {
        title: `Successfully sent update email request`,
      })
      return res
    })
    .catch(() =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while updating the email address. Please refresh the page and try again`,
      })
    )
}

export const getAssociatedLoanProducts = (orgId) => {
  return Api.get({
    url: `/Organizations/${orgId}/associated-loan-products`,
  }).catch(console.error)
}

export const getOrgStatesAndLoansAssociations = (orgId, orgType) => {
  const subPath =
    orgType === ORGANIZATION_TYPE_IDS.INSTALLER ? 'installers' : 'dealers'
  return Api.get({
    url: `/LoanProduct/${subPath}/${orgId}/products`,
  }).catch(console.error)
}

export const saveAssociatedLoanProducts = (dispatch, data) => {
  return Api.post({
    url: `/Organizations/associated-loan-products`,
    data: data,
  })
    .then(() =>
      showNotification(dispatch, {
        title: 'Loans association saved successfully',
      })
    )
    .catch(() => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: 'Error saving loan association',
      })
    })
}

export const saveAssociatedDealers = (installerOrgId, dealerAssociations) =>
  Api.post({
    url: `/Organizations/installers/dealers`,
    data: {
      installerId: installerOrgId,
      associations: dealerAssociations,
    },
  }).catch(console.error)

export const saveAssociatedProducts = (installerOrgId, dealerAssociations) =>
  Api.post({
    url: `/Organizations/installers/products`,
    data: {
      installerId: installerOrgId,
      associations: dealerAssociations,
    },
  }).catch(console.error)

export const copyAssociatedLoanProducts = (dispatch, fromOrgId, toOrgId) => {
  return Api.post({
    url: `/Organizations/assign-associated-loan-products`,
    data: {
      fromOrganizationId: fromOrgId,
      toOrganizationId: toOrgId,
    },
  })
    .then(() =>
      showNotification(dispatch, {
        title: `Successfully copied associated loan products.`,
      })
    )
    .catch((err) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `Something went wrong while copying associated loan products. Please try again.`,
      })
      throw err
    })
}

export const getStates = () => {
  return Api.get({
    url: '/Organizations/states',
  }).catch(console.error)
}

export const getLanguages = () => {
  return Api.get({
    url: '/Organizations/languages',
  }).catch(console.error)
}

export const getLanguageByOrgId = (orgId) => {
  return Api.get({
    url: `/Organizations/${orgId}/language`,
  }).catch(console.error)
}
