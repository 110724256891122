import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import Checkbox from 'components/checkbox'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { isSpecialCaseBank } from 'common/utils'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { useStore } from 'store'
import StatusComponent, { STATUSES } from 'components/status'
import { ExpandableCard } from 'components/expandable-card'

import InformationForm from './information-form'
import styles from './style.module.scss'
import { STATE_ID_TO_NAME, STATES_ORDER } from '../../constants'

const { Dialog } = components

const AutoPayCard = ({
  lenderId,
  achData,
  agreementText,
  loading,
  accountTypes,
  onSubmit,
  cancelButtonText,
  onCancel,
  secondaryUx = false,
  isCommercialApp,
  viewOnly,
}) => {
  const { t: translate } = useTranslation()
  const informationForm = useRef()
  const [areYouSureOpen, setAreYouSureOpen] = useState(false)
  const isCompleted = achData.achId && achData.status?.name === 'Complete'
  const { state } = useStore()
  const userData = state.session.userData
  const [optOut, setOptOut] = useState(false)
  const isEdit = achData.achVerificationAttempts > 0
  const currentState = STATE_ID_TO_NAME[state.flow.currentState?.id]
  const currentStateOrder = STATES_ORDER[currentState]
  const ntpFundsRequested = currentStateOrder >= STATES_ORDER.NTPFundsRequested
  const canSeeACHInfo = hasAccess(
    userData,
    CLAIMS.CAN_DECRYPT_ACH_SENSITIVE_DATA
  )
  const canEditAutoPayInfoAfterNTP = hasAccess(
    userData,
    CLAIMS.CAN_EDIT_ACH_AFTER_NTP_FUNDS_REQUESTED
  )
  const isECG = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)
  const canEditAutoPay =
    isECG || ntpFundsRequested ? canEditAutoPayInfoAfterNTP : !viewOnly

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const canReviewACH = useMemo(
    () => !isEdit || hasAccess(userData, CLAIMS.CAN_REVIEW_ACH),
    [userData, isEdit]
  )
  const onOptInOptOutClick = () => {
    if (optOut) {
      onCancel && onCancel()
    } else {
      informationForm.current.validateAndSubmit()
    }
  }

  const collapsibleItems = [
    <div className={styles.cardContent} key={0}>
      <div className={styles.cardContentContainer}>
        <div className={styles.cardContentText}>
          {agreementText}

          {secondaryUx ? (
            <div>
              <Checkbox
                label={translate('loanApplication.step3.autoPay.enrollLater')}
                onClick={() => setOptOut(!optOut)}
                checked={optOut}
              />
            </div>
          ) : null}
        </div>
        <div>
          <InformationForm
            hasWithdrawDateField={isSpecialCaseBank(lenderId)}
            ref={informationForm}
            achData={achData}
            accountTypes={accountTypes}
            canSeeACHInfo={canSeeACHInfo || canReviewACH}
            onSubmit={onSubmit}
            disabled={
              !hasAccess(userData, CLAIMS.CAN_ACCEPT_AGREEMENTS) || loading
            }
          />
        </div>
      </div>

      <div className="form-buttons__glendale">
        {!isCompleted && !secondaryUx && !isCommercialApp && (
          <Button
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            onClick={() => setAreYouSureOpen(true)}
            disabled={loading || viewOnly}
            sx={{ textWrap: 'nowrap' }}
          >
            {cancelButtonText ||
              translate('loanApplication.step3.autoPay.enrollAfterDocs')}
          </Button>
        )}

        {/* If the user doesn't have access to see sensitive data and we're in edit mode, hide the submit button */}
        {(canSeeACHInfo || !isEdit || canReviewACH) && (
          <Button
            onClick={onOptInOptOutClick}
            loading={loading}
            disabled={!canEditAutoPay}
            size={BUTTON_SIZES.SMALL}
            sx={{ textWrap: 'nowrap' }}
          >
            {translate(
              !optOut
                ? 'loanApplication.step3.autoPay.enrollNow'
                : 'loanApplication.step3.autoPay.enrollLater'
            )}
          </Button>
        )}
      </div>

      <Dialog
        open={areYouSureOpen}
        onClose={() => setAreYouSureOpen(false)}
        title={translate('loanApplication.step3.areYouSure')}
        onConfirm={() => {
          setAreYouSureOpen(false)
          onSubmit(null)
        }}
        actions={
          <>
            <Button
              onClick={() => setAreYouSureOpen(false)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button
              onClick={() => {
                setAreYouSureOpen(false)
                onSubmit(null)
              }}
            >
              {translate('buttons.yes')}
            </Button>
          </>
        }
      />
    </div>,
  ]

  return (
    <ExpandableCard
      sx={{ mb: '16px' }}
      headerProps={{
        sx: {
          backgroundColor: '#ffffff',
          padding: isMobileView ? '16px' : '24px',
        },
      }}
      expandableListStyle={{
        '& .MuiListItem-root': {
          padding: '0',
        },
      }}
      items={[]}
      collapsibleItems={collapsibleItems}
      isCollapsable={true}
      isCollapsed={true}
      alwaysCollapsible={true}
      title={
        <div className={styles.cardTitleBigger}>
          <span>{translate('loanApplication.step3.autoPay.title')}</span>

          <div className={styles.status}>
            <StatusComponent
              status={isCompleted ? STATUSES.ACTIVE : STATUSES.INACTIVE}
              label={
                isCompleted
                  ? translate('global.enrolled')
                  : translate('global.notEnrolled')
              }
            />
          </div>
        </div>
      }
    />
  )
}

AutoPayCard.propTypes = {
  lenderId: PropTypes.string,
  achData: PropTypes.object.isRequired,
  isAchAgreementAccepted: PropTypes.bool,
  agreementText: PropTypes.string,
  loading: PropTypes.bool,
  accountTypes: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  secondaryUx: PropTypes.bool,
  isCommercialApp: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

export default AutoPayCard
