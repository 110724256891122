import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { AccessWrapper, hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { compareIgnoreCase } from 'common/utils'
import { isEmpty } from 'components/validator'
import Loader, { LOADER_SIZE } from 'components/loader'
import AdminPageWrapper from 'modules/admin'
import OrganizationBreadcrumbs from 'modules/organizations/organization-details/breadcrumbs'
import {
  getOrganizationsTree,
  getRoles,
  getAllOrganizations,
  sendEmailUpdateEmail,
  sendResetPasswordEmail,
} from 'modules/organizations/actions'

import {
  resendEmailInvitation,
  getUserById,
  editUser,
  deleteUser,
} from 'modules/users/actions'
import { useStore } from 'store'
import UserSettings from 'modules/users/users-settings'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

const EditUserPage = ({ isUserModule }) => {
  const { state, dispatch } = useStore()
  const { Breadcrumbs } = components

  const navigate = useNavigate()
  const { id: orgId, userId } = useParams()

  const [userDetails, setUserDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const { userData } = state.session
  const { userRoles, organizationTree, allOrganizations } = state.orgManagement

  /** On mount, fetch user data & list of roles */
  useEffect(() => {
    getUserById(userId).then(setUserDetails)
    if (!userRoles.length) {
      getRoles(dispatch)
    }

    // For breadcrumbs
    if (!organizationTree.length) {
      getOrganizationsTree(dispatch)
    }
    if (
      !allOrganizations.length &&
      hasAccess(userData, CLAIMS.CAN_VIEW_ORGANIZATIONS)
    ) {
      getAllOrganizations(dispatch)
    }
  }, [orgId])

  const organizationOptions = useMemo(() => {
    const organizations = hasAccess(
      userData,
      CLAIMS.CAN_VIEW_CURRENT_AND_SUB_ORGANIZATIONS
    )
      ? organizationTree
      : allOrganizations

    return organizations.map((org) => ({ id: org.guid, label: org.name }))
  }, [allOrganizations, organizationTree, userData])

  const roleOptions = useMemo(
    () =>
      userRoles
        .map((role) => ({ id: role.roleId, label: role.name }))
        .sort((a, b) => compareIgnoreCase(a.label, b.label)),
    [userRoles]
  )

  const saveUser = (user) => {
    setLoading(true)
    return editUser(dispatch, user)
      .then((userData) => userData && setUserDetails(userData))
      .finally(() => setLoading(false))
  }

  const onUserRemove = () => {
    setLoading(true)
    return deleteUser(dispatch, userId)
      .then(() => {
        if (isUserModule) {
          navigate(`/admin/users`)
        } else {
          navigate(`/admin/organization/${orgId}`)
        }
      })
      .finally(() => setLoading(false))
  }

  const onEmailResend = () => {
    setLoading(true)
    return resendEmailInvitation(userDetails.emailAddress, dispatch).finally(
      () => setLoading(false)
    )
  }

  const onSendPasswordReset = () => {
    setLoading(true)
    return sendResetPasswordEmail(userDetails.emailAddress).finally(() =>
      setLoading(false)
    )
  }

  const onUpdateEmail = (user) => {
    setLoading(true)
    return sendEmailUpdateEmail(userId, user.emailAddress, dispatch).finally(
      () => setLoading(false)
    )
  }

  const isLoading = isEmpty(userDetails) || !organizationTree.length || loading

  const items = [
    { icon: <UsersSvg />, href: '/admin/users' },
    { label: 'User Details', href: `/admin/users/${userId}` },
    { label: 'Edit User' },
  ]

  return (
    <AccessWrapper claims={[CLAIMS.CAN_EDIT_USERS]}>
      <AdminPageWrapper>
        {isLoading ? (
          <Loader
            size={LOADER_SIZE.LARGE}
            center={true}
            style={{ marginTop: '100px' }}
          />
        ) : (
          <>
            {!isUserModule ? (
              <OrganizationBreadcrumbs
                orgId={orgId}
                organizationTree={organizationTree}
                finalItem={{ label: userDetails.fullName }}
              />
            ) : (
              <Breadcrumbs items={items} onClick={() => {}} sx={{ mb: 2 }} />
            )}
            <UserSettings
              userData={userDetails}
              onSave={saveUser}
              onDelete={onUserRemove}
              onEmailResend={onEmailResend}
              onUpdateEmail={onUpdateEmail}
              onSendPasswordReset={onSendPasswordReset}
              organizationOptions={organizationOptions}
              roleOptions={roleOptions}
              loading={loading}
            />
          </>
        )}
      </AdminPageWrapper>
    </AccessWrapper>
  )
}

EditUserPage.propTypes = {
  isUserModule: PropTypes.bool,
}

export default EditUserPage
