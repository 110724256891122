import React, { Fragment, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_VARIANTS } from 'components/button'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import { ReactComponent as CornerDownRightIcon } from 'assets/corner-down-right.svg'
import RemoveOverrideModal from '../modals/remove-override'
import OverrideReasonModal from '../modals/override-reason'
import LoanCardChip from '../loan-card/loan-card-chip'

import './style.scss'
import { EVALUATION_IDS } from '../../constants'

const { RefreshCWIcon, ReverseLeftIcon, ChevronUpIcon, ChevronDownIcon } =
  assets
const { Divider } = components

/**
 * Check List component used on the Loans module
 *
 * @param {Object} props
 * @param {Object} props.userData
 * @param {Array<Object>} props.checks - array of checks
 * @param {number} props.loanIndex - index of the loan
 * @param {boolean} props.actionsEnabled - if true, actions are enabled
 * @param {Function} props.fetchDecisioning - function to fetch decisioning
 * @param {Array<Object>} props.currentLoans - array of current loans
 * @param {Boolean} [props.viewOnly]
 * @constructor
 */
const LoanDecisionCheckList = ({
  userData,
  checks,
  loanIndex,
  actionsEnabled = true,
  fetchDecisioning,
  currentLoans,
  viewOnly,
}) => {
  const { t: translate } = useTranslation()
  const canOverrideDecision = hasAccess(
    userData,
    CLAIMS.CAN_OVERRIDE_EVALUATIONS
  )
  const canSeeOverriddenDecision = hasAccess(
    userData,
    CLAIMS.CAN_SEE_OVERRIDDEN_EVALUATION
  )
  const [reasonModalData, setReasonModalData] = useState(null)
  const [removeOverrideData, setRemoveOverrideData] = useState(null)
  const [isExpanded, setIsExpanded] = useState({})
  const sortedChecks = useMemo(
    () =>
      checks.sort((checkA, checkB) => {
        if (isFailedOrChildFailed(checkA)) {
          if (isFailedOrChildFailed(checkB)) {
            if (
              !checkA.reason &&
              (checkB.reason ||
                checkB.evaluatedChildDecisions?.some(
                  (child) => isFailed(child) && !!child.reason
                ))
            ) {
              return -1
            }
          } else {
            return -1
          }
        }
        return 0
      }),
    [JSON.stringify(checks)]
  )

  const toggleIsExpanded = (idx) => {
    setIsExpanded({ ...isExpanded, [idx]: !isExpanded[idx] })
  }

  const getDecisionStatusIcon = (check, hasReason) => {
    if (hasReason) {
      return (
        <LoanCardChip
          isFailed={false}
          style={{
            background: colors.blue[50],
            color: colors.blue[700],
          }}
          iconStyle={{ stroke: colors.blue[500] }}
          label={translate('loanApplication.step2.overridden')}
        />
      )
    }

    if (isFailed(check)) {
      return <LoanCardChip isFailed={true} />
    } else if (isSuccess(check)) {
      return <LoanCardChip isFailed={false} />
    }

    // Branch case
    return <LoanCardChip isBranch={true} />
  }

  // Recursive rendering of child decisions
  const _renderCheck = (check, idx, parentCheck, parentIdx) => {
    if (!check) {
      return null
    }
    const hasReason = actionsEnabled && canSeeOverriddenDecision && check.reason
    const isReasonRow = typeof check === 'string'
    const isChildRow = parentIdx !== undefined
    const className = !isChildRow ? 'check-list__item' : 'check-list__subcheck'

    return (
      <>
        <div className={className}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Row Icons */}
            {isChildRow && <CornerDownRightIcon />}
            {!isReasonRow && getDecisionStatusIcon(check, hasReason)}

            {/* Row Text */}

            <span className="reason-wrapper">
              {isReasonRow ? (
                <div className="reason-row">
                  <div className="reason-row__title">
                    {translate('loanApplication.step2.overrideReason')}
                  </div>
                  <div>{check}</div>
                </div>
              ) : (
                check.evaluationString
              )}
            </span>
          </div>

          <div style={{ marginLeft: 'auto' }}>
            {actionsEnabled &&
              !viewOnly &&
              canOverrideDecision &&
              isFailed(check) &&
              !check.isDisabled && (
                <Button
                  onClick={() =>
                    !viewOnly && setReasonModalData({ check, loanIndex })
                  }
                  variant={BUTTON_VARIANTS.TEXT}
                  style={{ color: colors.green[600] }}
                  endIcon={<RefreshCWIcon />}
                >
                  {translate('loanApplication.step2.override')}
                </Button>
              )}

            {hasReason && (
              <Button
                onClick={() => toggleIsExpanded(idx)}
                variant={BUTTON_VARIANTS.TEXT}
                style={{ color: colors.grey[500] }}
                endIcon={
                  isExpanded[idx] ? <ChevronUpIcon /> : <ChevronDownIcon />
                }
              >
                See Override
              </Button>
            )}

            {isExpanded[parentIdx] && canOverrideDecision && !viewOnly && (
              <Button
                onClick={() =>
                  setRemoveOverrideData({ check: parentCheck, loanIndex })
                }
                variant={BUTTON_VARIANTS.TEXT}
                style={{ color: colors.red[500] }}
                endIcon={<ReverseLeftIcon />}
              >
                {translate('loanApplication.step2.undoOverride')}
              </Button>
            )}
          </div>

          {/* Expanded section for Overridden Checks */}
          {isExpanded[idx] && (
            <Fragment key={`expanded-${idx}`}>
              {_renderCheck(check.reason, `expanded-${idx}`, check, idx)}
            </Fragment>
          )}

          {/* Branch case (child decisions) */}
          {check.evaluatedChildDecisions?.map((subCheck, idx) => (
            <Fragment key={idx}>
              {_renderCheck(subCheck, `child-${idx}`, check, idx)}
            </Fragment>
          ))}
        </div>
      </>
    )
  }

  return (
    <div className="check-list">
      {sortedChecks.map((check, idx) => (
        <Fragment key={idx}>
          {_renderCheck(check, idx)}
          {idx !== sortedChecks.length - 1 && <Divider />}
        </Fragment>
      ))}

      {/* Reason Modal for Overriding Decisions */}
      <OverrideReasonModal
        reasonModalData={reasonModalData}
        setReasonModalData={setReasonModalData}
        reFetchDecisioning={fetchDecisioning}
        currentLoans={currentLoans}
        userData={userData}
      />

      {/* Remove Reason Modal */}
      <RemoveOverrideModal
        removeOverrideData={removeOverrideData}
        setRemoveOverrideData={setRemoveOverrideData}
        reFetchDecisioning={fetchDecisioning}
        currentLoans={currentLoans}
      />
    </div>
  )
}

function isFailed(check) {
  return check.decisionResultId === EVALUATION_IDS.FAILED
}
function isFailedOrChildFailed(check) {
  return (
    isFailed(check) ||
    check.evaluatedChildDecisions?.some((childCheck) => isFailed(childCheck))
  )
}
function isSuccess(check) {
  return check.decisionResultId === EVALUATION_IDS.APPROVED
}

LoanDecisionCheckList.propTypes = {
  userData: PropTypes.object.isRequired,
  checks: PropTypes.arrayOf(
    PropTypes.shape({
      evaluationString: PropTypes.string,
      decisionResultId: PropTypes.number,
      evaluatedChildDecisions: PropTypes.array,
      reason: PropTypes.string,
    })
  ).isRequired,
  loanIndex: PropTypes.number,
  actionsEnabled: PropTypes.bool,
  fetchDecisioning: PropTypes.func,
  currentLoans: PropTypes.array,
  viewOnly: PropTypes.bool,
}

export default LoanDecisionCheckList
